import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import { useFormik } from 'formik';
import {store} from "react-notifications-component";
import {changePassword} from "../../services/auth.service";
import {toastWarning} from "../../utils/utils";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";


const useStyles = makeStyles((theme)=>({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10
    },
    btn: {
        marginTop: 16
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(8, 15, 10),
        overflowY: 'auto'
    },
}));


function ChangePassword(){

    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            confirm_new_password: ''
        },
        onSubmit: async (values) => {

            if (values.new_password !== values.confirm_new_password) {
                return toastWarning('Please provide the same password in both password fields!')
            }

            setLoading(true);

            try {
                const result = await changePassword(values.old_password, values.new_password);
                console.log('result.data -> ', result.data);

                store.addNotification({
                    title: "Password Changed",
                    message: "Successfully Changed Password",
                    type: "success",
                    insert: "bottom",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 2000,
                        showIcon: true
                    }
                });
            } catch (e) {
                // already toasted the error
            }

            setLoading(false);
        }
    });


    return (
        <div className={classes.container}>

            <div className={classes.modalPaper}>
                <h3 id="transition-modal-title">Change Password</h3>
                <br/>
                <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="old_password"
                                label="Old Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={formik.handleChange}
                                value={formik.values.old_password}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="new_password"
                                label="New Password"
                                type="password"
                                id="password"
                                onChange={formik.handleChange}
                                value={formik.values.new_password}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="confirm_new_password"
                                label="Confirm New Password"
                                type="password"
                                id="password"
                                onChange={formik.handleChange}
                                value={formik.values.confirm_new_password}
                            />
                        </Grid>

                    </Grid>
                    <Button
                        style={{marginTop: 24}}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Change
                    </Button>

                </form>
            </div>

            <LoaderWithBackDrop loading={loading} />

        </div>
    );
}

export default ChangePassword;