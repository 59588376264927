import React from 'react';
import { useAuth } from "../../context/auth";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme)=>({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10
    },
}));

function Home(){

    const classes = useStyles();

    const {authUser} = useAuth();

    return (
        <div className={classes.container}>
            <h3>Landed on Home Page</h3>
            <br />
            <p><b>Member_Id: {authUser.member_id}</b></p>
        </div>
    );
}

export default Home;