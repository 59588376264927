import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme)=>({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10
    },
}));

function Subscriptions(){

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <h3>Landed on Subscriptions Page | Inside Organizations</h3>
            <br />
            <h4><b>FUNCTIONALITY NOT IMPLEMENTED</b></h4>
        </div>
    );
}

export default Subscriptions;