import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button} from "@material-ui/core";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import { useFormik } from 'formik';
import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles((theme)=>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '90%',
        height: '85%',
        overflowY: 'scroll',
        overflowX: 'scroll'
    },
    btn: {
        marginTop: 16
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function EditEntityModal(props) {

    const { open, columns, formValues, title, handleClose, updateAPI } = props

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: formValues,
        onSubmit: async (values) => {

            console.log('form values --> ', values);
            updateAPI(values);

        }
    });


    return (

        <>
            <LoaderWithBackDrop loading={loading} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.modalPaper}>

                        <h2 id="transition-modal-title">{title}</h2>

                        <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>

                                {
                                    columns.map( col => <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            multiline
                                            rowsMax={6}
                                            id={col.json_key}
                                            label={col.name}
                                            name={col.json_key}
                                            onChange={formik.handleChange}
                                            value={formik.values[col.json_key]}
                                        />
                                    </Grid> )
                                }

                                <Grid item xs={12}>
                                </Grid>

                                <Grid item xs={4}>
                                </Grid>

                                <Grid item xs={4}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Save
                                    </Button>
                                </Grid>


                            </Grid>



                        </form>

                    </div>
                </Fade>
            </Modal>
        </>
    );
}

export default EditEntityModal;