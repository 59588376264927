import React from 'react';
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button, Select} from "@material-ui/core";

import { useFormik } from 'formik';
import TextField from "@material-ui/core/TextField";
import {ORG_TYPE} from "../../../utils/constants";


const useStyles = makeStyles((theme)=>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '70%',
        height: '85%',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    btn: {
        marginTop: 16
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


function EditEntityModal(props) {

    const { open, title, handleClose, submitCallback, dataObject } = props

    const classes = useStyles();

    const columns = [
        {
            key: 'org_code',
            label: 'Organization Code',
        },
        {
            key: 'org_type',
            label: 'Organization Type',
            drop_down: true,
            options: ORG_TYPE
        },
        {
            key: 'contact_email',
            label: 'Contact Email',
        },
        {
            key: 'org_name',
            label: 'Organization Name',
        },
        {
            key: 'org_address',
            label: 'Address',
        },
        {
            key: 'org_city',
            label: 'City',
        },
        {
            key: 'org_country',
            label: 'Country',
        },
        {
            key: 'callback_url',
            label: 'Callback Url'
        },
        {
            key: 'callback_key',
            label: 'Callback Key'
        }
    ]

    const formik = useFormik({
        initialValues: dataObject,
        onSubmit: async (values) => {

            console.log('UserModalForm.comp.jsx :: form values --> ', values);
            submitCallback(values);

        }
    });

    const isOrgCodeDisable = (col) => {
        if (col.key === 'org_code' && dataObject.id) {
            return true
        }
        return false
    }

    return (
        <>
            dataObject && <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.modalPaper}>

                        <h2 id="transition-modal-title">{title}</h2>
                        <br />
                        <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>

                                {
                                    columns.map( col => {
                                        if (col.drop_down) {
                                            return <Grid item xs={12}>
                                                <Select
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    id={col.key}
                                                    label={col.label}
                                                    name={col.key}
                                                    onChange={formik.handleChange}
                                                    value={formik.values[col.key]}
                                                >
                                                    {Object.keys(col.options).map(key => <option value={key}>{col.options[key]}</option> )}
                                                </Select>
                                            </Grid>
                                        }

                                        return <Grid item xs={12}>
                                            <TextField
                                                disabled={isOrgCodeDisable(col)}
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id={col.key}
                                                label={col.label}
                                                name={col.key}
                                                onChange={formik.handleChange}
                                                value={formik.values[col.key]}
                                            />
                                        </Grid>
                                    })
                                }

                                <Grid item xs={12}>
                                </Grid>

                                <Grid item xs={4}>
                                </Grid>

                                <Grid item xs={4}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Save
                                    </Button>
                                </Grid>


                            </Grid>

                        </form>

                    </div>
                </Fade>
            </Modal>
        </>
    );
}

export default EditEntityModal;